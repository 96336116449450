.titlePage {
  font-size: 40px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 200;
}

.widgetClassicDouble, .widgetClassic {
  width: 100%;
  border-radius: 5px;
  border: solid 1px #D5D5D5;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  max-height: calc(100vh - 300px);
  overflow: scroll;

  .headerWidget {
    background-color: #6E6E6E;
    color: #FFFFFF;
    padding: 10px 13px;
    border-radius: 5px 5px 0px 0px;
    margin-bottom: 5px;
    height: 20px;
    display: flex;
    align-items: center;
  }

  .info {
    color: #5661A4;
    margin-left: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .liteInfo {
    margin-left: 10px;
    margin-top: 17px;
    margin-bottom: 17px;
    font-weight: 300;
    font-size: 14px;
    opacity: 0.7;
  }

  .itemDataTable {
    display: flex;
    align-items: center;
    .nameItemDataTable {
      width: 162px;
      font-size: 14px;
      padding-left: 10px;
      color: #474747;
    }

    .valueItemDataTable {
    }
  }

  .itemTable {
    width: 100%;
    display: flex;
    font-weight: 300;

    .tableElem {
      border: solid 1px #D4D4D4;
      border-right: 0px;
      border-top: 0px;
      height: 45px;
      display: flex;
      align-items: center;
      padding-left: 15px;
    }

    .pdf {
      width: 18px;
      margin-left: 15px;
    }
  }

  .editContainer {
    color: #FFFFFF;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    margin-bottom: 8px;

    .editButton {
      background-color: #5965A8;
      padding: 10px 20px;
      font-size: 15px;
      font-weight: 700;
    }
  }
}

.widgetLight {

}
