.modalNotes {
  position: absolute;
  top: 0;
  margin-top: 35px;
  margin-left: 150px;
  width: calc(100vw - 300px);
  height: calc(100vh - 70px);
  border-radius: 3px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  padding-left: 13px;
  padding-right: 22px;

  .titleModalNotes {
    font-weight: 300;
    margin-top: 13px;
    color: #7E7E7E;
    margin-bottom: 25px;
  }

  .subtitleModalNotes {
    font-weight: 300;
    margin-top: 13px;
    font-size: 14px;
    color: #525252;
    font-style: italic;
  }

  .cross {
      position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    width: 15px;
   }
}

.OverlayModal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.66);
}
