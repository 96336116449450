// Colors
// 👇 TODO #7 : Change the main colors of your website

$color-primary: #007BFF;
$color-secondary: #55a7ff;
$color-tertiary: #9dcbff;
$color-light: #F8F9FA;
$color-dark: #343A40;
$color-gray: #6C757D;

// Typography
// 👇 TODO #8 : Change the font family of your website

$font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif;

// Transitions
$transition-base: all 0.3s ease-in-out;

body {
  margin: 0rem;
  font-family: $font-family-base;
  background-color: #FFFFFF;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: 500;
  word-wrap: break-word;
}

.page {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}


.hover:hover {
    cursor: pointer;
  opacity: 0.7;
}

.hover {
  opacity: 1;
}

/* App.css */
a {
  text-decoration: none;
  color: inherit;
}

a.active {
  /* Add your custom active styles here */
}
