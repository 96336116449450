.modalInfos {
  position: absolute;
  margin-top: calc((100vh - 500px)/2);
  margin-left: calc((100vw - 800px)/2);
  width: 800px;
  border-radius: 3px;
  height: 500px;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  display: flex;
  align-items: center;

  .contentModalInfos {
    width: 800px;
    background-color: #ffffff;
    padding-left: 13px;
    padding-right: 20px;
    border-radius: 3px;
    font-weight: 300;

    .headerModalInfos {
      display: flex;
      justify-content: space-between;
      font-weight: 300;
      color: #676767;
      .cross {
        top: 15px;
        right: 15px;
        cursor: pointer;
        width: 15px;
      }
    }

    .infos {
      margin-top: 5px;
      margin-bottom: 40px;
      color: #3d3d3d;
    }
  }
}
