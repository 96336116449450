.menu {
  width: 234px;
  height: 100vh;
  background-color: #4b4b4b;
  color: #FFFFFF;

  .nameNumber {
    color: #8A8FAF;
    font-weight: 700;
    margin-left: 20px;
    font-size: 14px;
  }

  .bar {
    background-color: #666666;
    height: 1px;
    margin-left: 20px;
    width: 214px;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .itemMenu:hover {
    opacity: 1;
  }

  .itemMenu, .itemMenuActive {
    width: 214px;
    padding-left: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
    color: #FFFFFF;
    cursor: pointer;
    opacity: 0.5;
    display: flex;
    align-items: center;
    text-decoration: none;

    .icon {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
  }

  .itemMenuActive {
    opacity: 1;
    color: #FFFFFF;
    background-color: #434343;
  }
}
